/* Elegant Typography and Base Styles */
body {
    margin: 0;
    font-family: 'Playfair Display', 'Times New Roman', serif;
    line-height: 1.6;
    color: #2c3e50;
    background-color: #ffffff;
}

.App {
    overflow-x: hidden;
}

.container {
    max-width: 1400px;
    margin: 0 auto;
    padding: 0 2rem;
}

main {
    margin-top: 2rem;
}

/* Global Animations and Transitions */
.App > * {
    animation: fadeIn 0.8s ease-in-out;
    transition: all 0.3s ease;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

/* Luxury Scroll Behavior */
html {
    scroll-behavior: smooth;
}

::-webkit-scrollbar {
    width: 8px;
}

::-webkit-scrollbar-track {
    background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
    background: #555;
}

/* Enhanced Section Spacing */
.App > div {
    margin: 4rem 0;
    padding: 2rem 0;
}

/* Luxury Hover Effects */
button, a {
    transition: all 0.3s ease;
}

button:hover, a:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

/* Text Enhancement */
h1, h2, h3, h4, h5, h6 {
    font-family: 'Playfair Display', serif;
    font-weight: 600;
    letter-spacing: 0.5px;
    color: #1a1a1a;
}

p {
    font-family: 'Lato', sans-serif;
    line-height: 1.8;
    color: #4a4a4a;
}

/* Luxury Box Shadows */
.card, .section {
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.card:hover, .section:hover {
    transform: translateY(-5px);
    box-shadow: 0 15px 40px rgba(0, 0, 0, 0.1);
}

/* Rich Background Gradients */
.gradient-bg {
    background: linear-gradient(135deg, #f6f9fc 0%, #ffffff 100%);
}
