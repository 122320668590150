.banner {
    position: fixed;
    top: 10px;
    right: 10px;
    padding: 15px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    width: 300px;
}

.banner-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.banner.success {
    background-color: #dff0d8;
    border: 1px solid #d0e9c6;
    color: #3c763d;
}

.banner.error {
    background-color: #f2dede;
    border: 1px solid #ebccd1;
    color: #a94442;
}

.banner button {
    background: none;
    border: none;
    color: inherit;
    font-size: 16px;
    cursor: pointer;
}
