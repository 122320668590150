/* Sidebar Styles */
.sidebar {
  width: 280px;
  background-color: #1e293b;
  color: #f1f5f9;
  transition: width 0.3s ease;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.sidebar.collapsed {
  width: 80px;
}

.sidebar-header {
  padding: 1.5rem 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #334155;
}

.sidebar-header h1 {
  font-size: 1.2rem;
  font-weight: bold;
  color: #f8fafc;
  margin: 0;
}

.toggle-btn {
  font-size: 1.5rem;
  color: #94a3b8;
  cursor: pointer;
  transition: color 0.3s ease;
}

.toggle-btn:hover {
  color: #f8fafc;
}

/* Menu Items */
.menu-items {
  list-style: none;
  padding: 0;
  margin: 0;
  flex: 1;
}

.menu-items li {
  margin: 0;
  padding: 1rem 1.5rem;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.menu-items li:hover {
  background-color: #475569;
  color: #e2e8f0;
}

.menu-items a {
  text-decoration: none;
  color: inherit;
  display: flex;
  align-items: center;
  width: 100%;
}

.menu-icon {
  font-size: 1.5rem;
  margin-right: 1rem;
  color: #94a3b8;
  transition: color 0.3s ease;
}

.sidebar.collapsed .menu-icon {
  margin: 0 auto;
}

.menu-items span {
  font-size: 1rem;
  font-weight: 500;
}

.sidebar.collapsed .menu-items span {
  display: none;
}

/* Footer */
.sidebar-footer {
  padding: 1rem;
  background-color: #334155;
  text-align: center;
}

.logout-link {
  background-color: #ef4444;
  color: #ffffff;
  border: none;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  font-weight: bold;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: 100%;
}

.logout-link:hover {
  background-color: #dc2626;
}
