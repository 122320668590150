/* UserManagementPage.css */

/* General container styling */
.user-management-container {
  padding: 20px;
  background-color: #f5f5f5;
  min-height: 100vh;
}

.user-management-actions {
  display: flex;
  gap: 15px;
}

/* Header styling with search and button */
.user-management-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

/* Search input styling */
.user-management-search {
  max-width: 300px;
  width: 100%;
}

/* Create User button styling */
.user-management-add-user-btn {
  background-color: #1890ff;
  color: #fff;
  border: none;
  padding: 8px 16px;
  font-size: 16px;
  font-weight: 500;
  transition: all 0.3s ease;
}

.user-management-add-user-btn:hover {
  background-color: #40a9ff;
  border-color: #40a9ff;
}

/* Table styling */
.ant-table {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.ant-table-thead > tr > th {
  background-color: #fafafa;
  font-weight: 600;
}

.ant-table-tbody > tr > td {
  font-size: 14px;
  padding: 16px;
}

.ant-table-tbody > tr:hover > td {
  background-color: #e6f7ff;
}

/* Action buttons */
.ant-btn-link {
  color: #1890ff;
  padding: 0;
  font-weight: 500;
  text-decoration: underline;
}

.ant-btn-link:hover {
  color: #40a9ff;
}

/* Modal styling */
.ant-modal-header {
  background-color: #fafafa;
  border-bottom: none;
  padding: 16px 24px;
}

.ant-modal-title {
  font-size: 18px;
  font-weight: 600;
}

.ant-modal-footer {
  border-top: none;
  padding: 12px 24px;
}

.ant-modal-close {
  top: 18px;
  right: 18px;
}

.ant-modal-body {
  padding: 24px;
}

/* Form styling */
.ant-form-item {
  margin-bottom: 16px;
}

.ant-form-item-label > label {
  font-weight: 500;
}

.ant-input,
.ant-select-selector {
  height: 40px;
  border-radius: 4px;
}

.ant-input:focus,
.ant-input:hover,
.ant-select-selector:focus,
.ant-select-selector:hover {
  border-color: #40a9ff;
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
}

.ant-btn-primary {
  background-color: #1890ff;
  border-color: #1890ff;
  font-size: 16px;
  font-weight: 500;
}

.ant-btn-primary:hover {
  background-color: #40a9ff;
  border-color: #40a9ff;
}

/* View details modal */
.ant-modal-user-details p {
  font-size: 14px;
  line-height: 1.6;
  margin-bottom: 8px;
}

.ant-modal-user-details strong {
  font-weight: 600;
}

/* Responsive Styling */

/* Medium devices (tablets, 768px and above) */
@media (max-width: 768px) {
  .user-management-container {
      padding: 10px;
  }

  .user-management-header {
      flex-direction: column;
      align-items: flex-start;
  }

  .user-management-search {
      width: 100%;
      margin-bottom: 10px;
  }

  .user-management-add-user-btn {
      width: 100%;
      padding: 10px;
      text-align: center;
  }

  .ant-table {
      font-size: 12px;
  }

  .ant-table-tbody > tr > td {
      padding: 12px;
  }
}

/* Small devices (mobile phones, 480px and above) */
@media (max-width: 480px) {
  .user-management-header {
      flex-direction: column;
      align-items: stretch;
  }

  .user-management-search {
      width: 100%;
      margin-bottom: 15px;
  }

  .user-management-add-user-btn {
      width: 100%;
      padding: 12px;
      text-align: center;
  }

  .ant-table-thead > tr > th {
      font-size: 14px;
  }

  .ant-table-tbody > tr > td {
      font-size: 12px;
      padding: 10px;
  }

  .ant-modal-body {
      padding: 10px;
  }

  .ant-form-item {
      margin-bottom: 10px;
  }
}
