.exclusive-offers {
    padding: 4rem 2rem;
    background: linear-gradient(135deg, #f8f9fa 0%, #ffffff 100%);
    position: relative;
    overflow: hidden;
}

.exclusive-offers::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(45deg, rgba(255,255,255,0.95) 0%, rgba(255,255,255,0.5) 100%);
    z-index: 1;
}

.offers-container {
    max-width: 1400px;
    margin: 0 auto;
    position: relative;
    z-index: 2;
}

.section-header {
    text-align: center;
    margin-bottom: 3rem;
}

.section-header h2 {
    font-family: 'Playfair Display', serif;
    font-size: 2.5rem;
    color: #1a1a1a;
    margin: 0;
    margin-bottom: 1rem;
}

.section-subtitle {
    font-size: 1.1rem;
    color: #666;
    margin: 0;
}

.offers-list {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    margin-bottom: 3rem;
}

.offer-item {
    display: grid;
    grid-template-columns: auto 1fr auto;
    gap: 2rem;
    align-items: center;
    background: white;
    padding: 2rem;
    border-radius: 15px;
    transition: all 0.3s ease;
    cursor: pointer;
    box-shadow: 0 10px 30px rgba(0,0,0,0.05);
}

.offer-item:hover {
    transform: translateY(-5px);
    box-shadow: 0 20px 40px rgba(0,0,0,0.1);
}

.offer-icon {
    width: 80px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-size: 2rem;
    color: white;
    flex-shrink: 0;
}

.offer-details {
    flex-grow: 1;
}

.offer-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
}

.offer-header h3 {
    font-family: 'Playfair Display', serif;
    font-size: 1.5rem;
    color: #1a1a1a;
    margin: 0;
}

.offer-category {
    font-size: 0.9rem;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 600;
}

.offer-description {
    color: #666;
    margin: 0;
    margin-bottom: 1rem;
    line-height: 1.6;
}

.offer-pricing {
    display: flex;
    gap: 2rem;
}

.price-item {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.treatment-name {
    color: #444;
    font-weight: 500;
}

.treatment-price {
    font-weight: 600;
    font-size: 1.1rem;
}

.view-details {
    padding: 0.75rem 1.5rem;
    border-radius: 50px;
    border: 2px solid;
    background: transparent;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s ease;
    white-space: nowrap;
}

.offers-banner {
    background: linear-gradient(135deg, #4a90e2 0%, #357abd 100%);
    padding: 1rem;
    border-radius: 10px;
    color: white;
    margin-top: 2rem;
}

.offers-banner marquee {
    font-size: 1.1rem;
}

.offers-banner span {
    margin-right: 3rem;
}

/* Modal Styles */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0,0,0,0.7);
    backdrop-filter: blur(5px);
    z-index: 9998;
}

.offer-modal {
    position: fixed;
    top: 10%;
    transform: translate(-50%, -50%);
    background: white;
    border-radius: 20px;
    padding: 2rem;
    width: 90%;
    max-width: 600px;
    max-height: 85vh;
    overflow-y: auto;
    z-index: 9999;
    box-shadow: 0 20px 40px rgba(0,0,0,0.2);
}

.close-modal {
    position: absolute;
    top: 1rem;
    right: 1rem;
    background: none;
    border: none;
    font-size: 1.5rem;
    color: #666;
    cursor: pointer;
    padding: 0.5rem;
    transition: all 0.3s ease;
}

.close-modal:hover {
    color: #1a1a1a;
    transform: rotate(90deg);
}

.modal-icon {
    width: 80px;
    height: 80px;
    background: var(--card-color);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    margin-bottom: 1.5rem;
    font-size: 2rem;
    color: white;
}

.modal-category {
    text-align: center;
    font-size: 1rem;
    color: var(--card-color);
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 600;
    margin-bottom: 0.5rem;
}

.offer-modal h3 {
    font-family: 'Playfair Display', serif;
    font-size: 2rem;
    color: #1a1a1a;
    text-align: center;
    margin: 0;
    margin-bottom: 1rem;
}

.modal-description {
    text-align: center;
    color: #666;
    margin-bottom: 2rem;
    line-height: 1.6;
}

.modal-treatments {
    background: #f8f9fa;
    border-radius: 10px;
    padding: 1.5rem;
    margin-bottom: 2rem;
}

.modal-treatment {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.75rem 0;
    border-bottom: 1px dashed rgba(0,0,0,0.1);
}

.modal-treatment:last-child {
    border-bottom: none;
}

.modal-treatment .name {
    color: #444;
    font-weight: 500;
}

.modal-treatment .price {
    color: var(--card-color);
    font-weight: 600;
    font-size: 1.1rem;
}

.modal-features {
    margin-bottom: 2rem;
}

.modal-features h4 {
    font-family: 'Playfair Display', serif;
    color: #1a1a1a;
    margin-bottom: 1rem;
}

.modal-features ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.modal-features li {
    padding-left: 1.5rem;
    position: relative;
    margin-bottom: 0.75rem;
    color: #666;
}

.modal-features li::before {
    content: '✓';
    position: absolute;
    left: 0;
    color: var(--card-color);
}

.book-now {
    width: 100%;
    padding: 1rem;
    color: white;
    border: none;
    border-radius: 10px;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s ease;
}

.book-now:hover {
    filter: brightness(1.1);
    transform: translateY(-2px);
}

/* Responsive Styles */
@media screen and (max-width: 1200px) {
    .exclusive-offers {
        padding: 3rem 1.5rem;
    }

    .section-header h2 {
        font-size: 2rem;
    }
}

@media screen and (max-width: 992px) {
    .offer-item {
        grid-template-columns: auto 1fr;
        gap: 1.5rem;
    }

    .view-details {
        display: none;
    }

    .offer-pricing {
        flex-direction: column;
        gap: 0.5rem;
    }
}

@media screen and (max-width: 576px) {
    .exclusive-offers {
        padding: 2rem 1rem;
    }

    .section-header h2 {
        font-size: 1.75rem;
    }

    .offer-item {
        padding: 1.5rem;
    }

    .offer-icon {
        width: 60px;
        height: 60px;
        font-size: 1.5rem;
    }

    .offer-header {
        flex-direction: column;
        align-items: flex-start;
        gap: 0.5rem;
    }

    .offer-header h3 {
        font-size: 1.25rem;
    }

    .price-item {
        flex-direction: column;
        align-items: flex-start;
        gap: 0.25rem;
    }

    .offer-modal {
        padding: 1.5rem;
    }

    .modal-icon {
        width: 60px;
        height: 60px;
        font-size: 1.5rem;
    }

    .offer-modal h3 {
        font-size: 1.5rem;
    }
}

/* Custom Scrollbar */
.offer-modal::-webkit-scrollbar {
    width: 8px;
}

.offer-modal::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 4px;
}

.offer-modal::-webkit-scrollbar-thumb {
    background: var(--card-color, #4a90e2);
    border-radius: 4px;
}

.offer-modal::-webkit-scrollbar-thumb:hover {
    background: var(--card-color, #357abd);
}
