.bill-generator-container {
    display: flex;
    flex-direction: row;
    gap: 20px;
    padding: 20px;
    background-color: #f5f9ff;
    border-radius: 10px;
  }

  @media screen and (max-width: 768px) {
    .bill-generator-container {
        display: flex;
        flex-direction: column;
    }
}
  
  .form-container {
    background-color: #e7f0fd;
    padding: 20px;
    border-radius: 10px;
    flex: 1;
  }
  
  .input-group {
    display: flex;
    flex-direction: column;
  }
  
  .input-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  
  .input-field {
    flex: 1;
    margin-right: 10px;
  }
  
  .input-field label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  .input-field input {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .add-payment-btn {
    background-color: #008cba;
    color: white;
    padding: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .add-payment-btn:hover {
    background-color: #008cba;
  }
  
  .preview-container {
    flex: 1;
    background-color: #e7f0fd;
    padding: 20px;
    border-radius: 10px;
  }
  
  .preview-box {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .preview-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
  }
  
  .preview-table th, .preview-table td {
    border: 1px solid #ccc;
    padding: 8px;
    text-align: left;
  }
  
  .preview-table th {
    background-color: #f2f2f2;
  }
  
  .download-btn {
    background-color: #008cba;
    color: white;
    padding: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 20px;
  }
  
  .download-btn:hover {
    background-color: #007bb5;
  }
  