.header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 9997;
    background: white;
}

.top-bar {
    background: linear-gradient(135deg, #4a90e2 0%, #357abd 100%);
    color: white;
}

.contact-info {
    max-width: 1400px;
    margin: 0 auto;
    padding: 0.5rem 2rem;
    display: flex;
    justify-content: flex-start;
    gap: 5rem;
    align-items: center;
}

.contact-item {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: white;
    text-decoration: none;
    font-size: 0.9rem;
}

.contact-item svg {
    font-size: 1rem;
}

.header-content {
    max-width: 1400px;
    margin: 0 auto;
    padding: 1rem 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: white;
    border-bottom: 1px solid rgba(0,0,0,0.1);
}

.logo {
    font-family: 'Playfair Display', serif;
    font-size: 1.5rem;
    font-weight: bold;
    color: #1a1a1a;
    text-decoration: none;
}

.menu-btn {
    display: none;
    background: none;
    border: none;
    font-size: 1.5rem;
    color: #1a1a1a;
    cursor: pointer;
    padding: 0.5rem;
}

.nav-links {
    display: flex;
    align-items: center;
    gap: 2rem;
}

.nav-links a {
    color: #1a1a1a;
    text-decoration: none;
    font-weight: 500;
    transition: color 0.3s ease;
}

.nav-links a:hover {
    color: #4a90e2;
}

.mobile-contact-info {
    display: none;
}

.book-appointment-btn {
    background: linear-gradient(135deg, #4a90e2 0%, #357abd 100%);
    color: white;
    padding: 0.75rem 1.5rem;
    border-radius: 50px;
    border: none;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s ease;
}

.book-appointment-btn:hover {
    transform: translateY(-2px);
    box-shadow: 0 5px 15px rgba(0,0,0,0.2);
}

/* Appointment Form Modal */
.appointment-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0,0,0,0.7);
    backdrop-filter: blur(5px);
}

.appointment-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white;
    border-radius: 20px;
    width: 90%;
    max-width: 600px;
    max-height: 85vh;
    overflow-y: auto;
    z-index: 9999;
    box-shadow: 0 20px 40px rgba(0,0,0,0.2);
}

@media screen and (max-width: 992px) {
    .contact-info {
        padding: 0 1rem;
        font-size: 0.8rem;
        gap: 1rem;
        flex-wrap: wrap;
    }

    .header-content {
        padding: 1rem;
    }

    .menu-btn {
        display: block;
    }

    .nav-links {
        position: fixed;
        top: 120px;
        left: 0;
        right: 0;
        background: linear-gradient(to bottom, rgba(255, 255, 255, 0.95), rgba(255, 255, 255, 0.98));
        backdrop-filter: blur(10px);
        flex-direction: column;
        padding: 2rem;
        gap: 1.5rem;
        transform: translateX(-100%);
        transition: transform 0.5s cubic-bezier(0.4, 0, 0.2, 1);
        height: calc(100vh - 120px);
        overflow-y: auto;
        border-right: 1px solid rgba(74, 144, 226, 0.1);
        box-shadow: 5px 0 25px rgba(0, 0, 0, 0.1);
    }

    .nav-links.active {
        transform: translateX(0);
        box-shadow: 5px 0 25px rgba(74, 144, 226, 0.15);
    }

    .nav-links a {
        position: relative;
        padding: 0.8rem 1.2rem;
        border-radius: 8px;
        transition: all 0.3s ease;
        background: linear-gradient(to right, transparent 50%, rgba(74, 144, 226, 0.1) 50%);
        background-size: 200% 100%;
        background-position: left bottom;
    }

    .nav-links a:hover {
        background-position: right bottom;
        transform: translateX(10px);
    }

    .nav-links a::before {
        content: '';
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 3px;
        height: 0;
        background: linear-gradient(135deg, #4a90e2 0%, #357abd 100%);
        transition: height 0.3s ease;
        border-radius: 0 3px 3px 0;
    }

    .nav-links a:hover::before {
        height: 70%;
    }

    .mobile-contact-info {
        display: flex;
        flex-direction: column;
        padding: 1rem;
        background: #f8f9fa;
        border-radius: 10px;
    }

    .mobile-contact-info .contact-item {
        color: #1a1a1a;
    }

    .appointment-modal {
        width: 95%;
        max-height: 90vh;
    }
}

@media screen and (max-width: 768px) {
    .contact-info {
        display: none;
    }

    .nav-links {
        top: 80px;
        height: calc(100vh - 80px);
    }
}

@media screen and (max-width: 576px) {
    .header-content {
        padding: 0.75rem 1rem;
    }

    .logo {
        font-size: 1.25rem;
    }

    .appointment-modal {
        width: 100%;
        height: 100vh;
        max-height: 100vh;
        border-radius: 0;
        top: 0;
        transform: translate(-50%, 0);
    }
}

.promo-banner {
  background-color: #004080;
  color: #fff;
  padding: 20px;
  text-align: center;
  font-size: 1.1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.promo-banner h2 {
  margin: 0;
  font-size: 1.8rem;
  font-weight: bold;
  color: #ffcc00;
  margin-bottom: 20px;
  text-transform: uppercase;
  letter-spacing: 1.2px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.offer-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 15px;
  width: 100%;
  max-width: 1200px;
}

.offer-card {
  background-color: #ffcc00;
  color: #333;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  font-size: 1rem;
  position: relative;
  transition: transform 0.3s ease, background-color 0.3s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100px; /* Reduced Height */
  justify-content: space-between;
}

.offer-card:hover {
  transform: translateY(-5px);
  background-color: #ffdd44;
}

.offer-badge {
  background-color: #c400ff;
  color: #fff;
  font-weight: bold;
  font-size: 0.7rem; /* Slightly Smaller Badge */
  padding: 3px 8px;
  border-radius: 5px;
  position: absolute;
  top: -10px;
  left: 10px;
  z-index: 1;
}

.offer-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: bold;
  font-size: 0.9rem; /* Adjusted font-size */
}

.offer-button {
  background-color: #004080;
  color: #fff;
  padding: 5px 15px;
  border-radius: 5px;
  font-size: 0.8rem; /* Reduced font-size */
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: background-color 0.3s ease;
  cursor: pointer;
  margin-top: 5px;
}

.offer-button:hover {
  background-color: #002244;
}

.offer-card span {
  display: block;
  margin-top: 2px;
}

.offer-content .emoji {
  font-size: 1.1rem;
  margin-right: 5px;
}

/* Responsive Styling */
@media screen and (max-width: 768px) {
  .promo-banner h2 {
    font-size: 1.6rem;
  }

  .offer-grid {
    grid-template-columns: repeat(4, 1fr); /* 4 Cards in a Row for Mobile */
  }

  .offer-card {
    height: 90px; /* Further reduced height for mobile */
  }
}

@media screen and (max-width: 480px) {
  .promo-banner h2 {
    font-size: 1.4rem;
  }

  .offer-grid {
    grid-template-columns: repeat(1, 1fr); /* Maintain 4 Cards in a Row */
  }

  .offer-card {
    height: 110px; /* Further reduced height for small screens */
  }

  .offer-button {
    font-size: 0.7rem;
    padding: 3px 10px;
  }
}

.carousal-banner {
  background-color: #ffcc00; /* Bright color to stand out */
  color: #333;
  padding: 10px 0;
  font-weight: bold;
  text-align: center;
  font-size: 1.2rem;
}

.carousal-banner marquee {
  width: 100%;
  font-size: 1.1rem;
}

/* Tablet View */
@media screen and (max-width: 768px) {
  .carousal-banner {
    font-size: 1rem;
  }
}

/* Mobile View */
@media screen and (max-width: 480px) {
  .carousal-banner {
    font-size: 0.9rem;
  }
}
