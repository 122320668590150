.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);
    backdrop-filter: blur(5px);
    z-index: 1000;
}

.appointment-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white;
    border-radius: 20px;
    padding: 2rem;
    width: 90%;
    max-width: 600px;
    max-height: 90vh;
    overflow-y: auto;
    z-index: 1001;
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.2);
}

.modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;
}

.modal-header h2 {
    font-family: 'Playfair Display', serif;
    font-size: 1.75rem;
    color: #1a1a1a;
    margin: 0;
}

.close-button {
    background: none;
    border: none;
    font-size: 1.5rem;
    color: #666;
    cursor: pointer;
    padding: 0.5rem;
    transition: all 0.3s ease;
}

.close-button:hover {
    color: #1a1a1a;
    transform: rotate(90deg);
}

.appointment-form {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.form-row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
}

.form-group {
    display: flex;
    flex-direction: column;
}

.form-group input,
.form-group select,
.form-group textarea {
    padding: 1rem;
    border: 2px solid #eee;
    border-radius: 10px;
    font-size: 1rem;
    transition: all 0.3s ease;
    background: #f8f9fa;
}

.form-group input:focus,
.form-group select:focus,
.form-group textarea:focus {
    border-color: #4a90e2;
    outline: none;
    box-shadow: 0 0 0 3px rgba(74, 144, 226, 0.1);
}

.form-group textarea {
    resize: vertical;
    min-height: 100px;
}

.submit-button {
    background: linear-gradient(135deg, #4a90e2 0%, #357abd 100%);
    color: white;
    border: none;
    border-radius: 10px;
    padding: 1rem;
    font-size: 1rem;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.3s ease;
    margin-top: 1rem;
}

.submit-button:hover {
    transform: translateY(-2px);
    box-shadow: 0 5px 15px rgba(74, 144, 226, 0.3);
}

/* Responsive Styles */
@media screen and (max-width: 576px) {
    .appointment-modal {
        padding: 1.5rem;
    }

    .modal-header h2 {
        font-size: 1.5rem;
    }

    .form-row {
        grid-template-columns: 1fr;
    }

    .form-group input,
    .form-group select,
    .form-group textarea {
        padding: 0.875rem;
        font-size: 0.9rem;
    }

    .submit-button {
        padding: 0.875rem;
        font-size: 0.9rem;
    }
}

/* Custom Scrollbar */
.appointment-modal::-webkit-scrollbar {
    width: 8px;
}

.appointment-modal::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 4px;
}

.appointment-modal::-webkit-scrollbar-thumb {
    background: #4a90e2;
    border-radius: 4px;
}

.appointment-modal::-webkit-scrollbar-thumb:hover {
    background: #357abd;
}
