/* Hero Section Base Styles */
.hero-section {
    position: relative;
    height: 100vh;
    min-height: 600px;
    background: url('../../images/yoga1.jpg') center/cover no-repeat;
    display: flex;
    align-items: center;
    padding: 0 2rem;
    overflow: hidden;
}

.hero-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
        135deg,
        rgba(0, 0, 0, 0.8) 0%,
        rgba(0, 0, 0, 0.6) 100%
    );
}

.hero-content {
    position: relative;
    max-width: 1400px;
    margin: 0 auto;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 80px;
}

.hero-text {
    max-width: 600px;
    color: white;
}

.hero-text h1 {
    font-family: 'Playfair Display', serif;
    font-size: 3.5rem;
    margin: 0;
    margin-bottom: 1.5rem;
    line-height: 1.2;
}

.hero-text p {
    font-size: 1.2rem;
    margin: 0;
    margin-bottom: 2rem;
    line-height: 1.6;
    opacity: 0.9;
}

.hero-buttons {
    display: flex;
    gap: 1rem;
}

.book-button,
.whatsapp-button {
    padding: 1rem 2rem;
    font-size: 1rem;
    font-weight: 500;
    border: none;
    border-radius: 50px;
    cursor: pointer;
    transition: all 0.3s ease;
}

.book-button {
    background: linear-gradient(135deg, #4a90e2 0%, #357abd 100%);
    color: white;
}

.whatsapp-button {
    background: linear-gradient(135deg, #25D366 0%, #128C7E 100%);
    color: white;
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.whatsapp-button:hover {
    transform: translateY(-2px);
    box-shadow: 0 5px 15px rgba(37, 211, 102, 0.3);
}

.floating-card {
    background: rgba(255, 255, 255, 0.95);
    padding: 2rem;
    border-radius: 20px;
    max-width: 350px;
    backdrop-filter: blur(10px);
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.2);
}

.floating-card h3 {
    font-family: 'Playfair Display', serif;
    font-size: 1.5rem;
    color: #1a1a1a;
    margin: 0;
    margin-bottom: 1rem;
}

.whatsapp-button {
    display: block;
}

.floating-card p {
    color: #666;
    margin: 0;
    margin-bottom: 1.5rem;
    line-height: 1.6;
}

.floating-card ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.floating-card li {
    color: #444;
    margin-bottom: 0.75rem;
    padding-left: 1.5rem;
    position: relative;
}

.floating-card li::before {
    content: '•';
    color: #4a90e2;
    position: absolute;
    left: 0;
    font-size: 1.5rem;
    line-height: 1;
}

/* Responsive Styles */
@media screen and (max-width: 1200px) {
    .hero-text h1 {
        font-size: 3rem;
    }
}

@media screen and (max-width: 992px) {
    .hero-content {
        flex-direction: column;
        text-align: center;
        gap: 3rem;
    }

    .hero-text {
        max-width: 100%;
    }

    .hero-buttons {
        justify-content: center;
    }

    .floating-card {
        max-width: 100%;
    }
}

@media screen and (max-width: 576px) {
    .hero-section {
        padding: 0 1rem;
    }

    .hero-text h1 {
        font-size: 2.5rem;
    }

    .hero-text p {
        font-size: 1.1rem;
    }

    .hero-buttons {
        flex-direction: column;
    }

    .book-button,
    .whatsapp-button {
        width: 100%;
    }
}
