.patient-record-management-container {
    padding: 20px;
}

.patient-record-management-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.patient-record-management-header .search-bar {
    width: 300px;
}

.patient-record-management-header .date-picker {
    margin-right: 20px;
}

@media (max-width: 768px) {
    .patient-record-management-header {
        flex-direction: column;
        align-items: flex-start;
    }

    .patient-record-management-header > button,
    .patient-record-management-header .search-bar {
        margin-top: 10px;
        width: 100%;
    }
    
    .patient-record-management-header .date-picker {
        margin-right: 0;
        margin-top: 10px;
    }
}

.ant-modal .ant-form-item {
    margin-bottom: 16px;
}

.ant-table-wrapper {
    overflow-x: auto;
}

.appointment-actions-container {
    margin-top: 20px;
}

.appointment-actions-container .action-btn {
    margin-right: 10px;
}

.appointment-table-container {
    margin-top: 20px;
}

.patient-record-modal .form-group {
    margin-bottom: 16px;
}

.patient-record-modal .form-group label {
    display: block;
    font-weight: bold;
}

.patient-record-modal .input-error {
    border-color: red;
}

.patient-record-modal .error-message {
    color: red;
    font-size: 12px;
}

.patient-record-modal .submit-button {
    margin-top: 20px;
    width: 100%;
}

.patient-record-management-container .loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
}

.patient-record-management-container .loading-container p {
    margin-left: 10px;
}

.treatment-action-buttons {
    gap: 20px;
    display: flex;
}