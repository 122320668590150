.appointment {
  padding: 4rem 2rem;
  background-color: white;
  text-align: center;
}

.appointment .appointment-container {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 2rem;
  width: 100%;
  /* max-width: 1200px; */
  margin: 0 auto;
}

.appointment-content {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.appointment-form {
  background: #fff;
  padding: 2rem;
  border-radius: 15px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  text-align: left;
  width: 48%;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  background-color: #81a5d175;
}

.appointment-form:hover {
  transform: translateY(-10px);
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.2);
}

.appointment-form h3 {
  margin-bottom: 1.5rem;
  color: #333;
  font-size: 1.75rem;
  font-weight: 700;
}

.appointment-form .form-group {
  display: flex;
  gap: 1rem;
  margin-bottom: 1.5rem;
}

.appointment-form .form-group input,
.appointment-form .form-group select,
.appointment-form .form-group textarea {
  width: 100%;
  padding: 1rem;
  border: 1px solid #ddd;
  border-radius: 10px;
  background-color: #f8f8f8;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
  transition: border-color 0.3s ease, background-color 0.3s ease;
}

.appointment-form .form-group input:focus,
.appointment-form .form-group select:focus,
.appointment-form .form-group textarea:focus {
  border-color: #0056b3;
  background-color: #fff;
  outline: none;
}

.appointment-form .form-group.full-width {
  margin-bottom: 2rem;
}

.appointment-submit-button {
  background-color: #007bff; /* Blue background for enabled state */
  color: #fff;
  border: none;
  padding: 1rem;
  font-size: 1.2rem;
  cursor: pointer;
  border-radius: 40px;
  transition: background-color 0.3s ease, transform 0.3s ease;
  width: 100%;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.appointment-submit-button:disabled {
  background-color: #b0b0b0; /* Grey background for disabled state */
  cursor: not-allowed;
}

.appointment-submit-button:hover:enabled {
  background-color: #0056b3;
  transform: translateY(-5px);
}

.appointment-submit-button:hover:disabled {
  background-color: #b0b0b0; /* Maintain grey on hover for disabled state */
}

/* Tablet View */
@media (max-width: 768px) {
  .appointment .appointment-container {
    flex-direction: column;
    align-items: center;
  }

  .appointment-content {
    flex-direction: column;
  }

  .appointment-form {
    width: 80%;
    margin-bottom: 2rem;
  }

  .appointment-form h3 {
    font-size: 1.5rem;
  }

  .appointment-submit-button {
    font-size: 1.1rem;
  }
}

/* Mobile View */
@media (max-width: 480px) {
  .appointment {
    padding: 2rem 1rem;
  }

  .appointment-form {
    width: 100%;
    padding: 1.5rem;
  }

  .appointment-form h3 {
    font-size: 1.25rem;
  }

  .appointment-form .form-group {
    flex-direction: column;
  }

  .appointment-form .form-group input,
  .appointment-form .form-group select,
  .appointment-form .form-group textarea {
    padding: 0.75rem;
  }

  .appointment-form .form-group.full-width {
    margin-bottom: 1rem;
  }

  .appointment-submit-button {
    font-size: 1rem;
    padding: 0.75rem;
  }
}

.time-slot-popup {
  /* background-color: #81a5d175; Light blue with opacity */
  background:#a4bedd ;
  padding: 2rem;
  border-radius: 15px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  position: fixed; /* Changed to fixed to cover the viewport */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); /* Center the popup */
  z-index: 999; /* Ensure it sits above other elements */
  width: 80%; /* Optional: Set a width to control size */
  max-width: 600px; /* Optional: Set a max-width to prevent it from being too large */
}


.time-slot-popup h4 {
  margin-bottom: 1rem;
  font-size: 1.5rem;
  color: #333;
}

.time-slots {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;
  margin-bottom: 10px;
}

.time-slot{
  width: 100px;
  height: 40px;
  border-radius: 5px;
  background: #81a5d175;
}

.time-slot.selected{
  background: #6381a6;
}

.time-slot-button {
  background-color: #004080;
  color: #fff;
  border: none;
  padding: 1rem;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
  text-align: center;
  font-size: 1rem;
}

.time-slot-button:hover {
  background-color: #0056b3;
  transform: translateY(-3px);
}

.time-slot-button:active {
  background-color: #004494;
}

.popup-actions{
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
}

.popup-actions__close{
  width: 100px;
  height: 40px;
  border-radius: 5px;
  background: #6381a6;
}

.popup-actions__confirm{
  width: 100px;
  height: 40px;
  border-radius: 5px;
  background: #6381a6;
}

.appointment-date-input {
  background-color: #f8f8f8;
  border: 1px solid #ddd;
  padding: 1rem;
  border-radius: 10px;
  transition: border-color 0.3s ease, background-color 0.3s ease;
}

.appointment-date-input:focus {
  border-color: #0056b3;
  background-color: #fff;
  outline: none;
}

/* Tablet View */
@media (max-width: 768px) {
  .appointment .appointment-container {
    flex-direction: column;
    align-items: center;
  }

  .appointment-content {
    flex-direction: column;
  }

  .appointment-form {
    width: 80%;
    margin-bottom: 2rem;
  }

  .appointment-form h3 {
    font-size: 1.5rem;
  }

  .appointment-submit-button {
    font-size: 1.1rem;
  }
}

/* Mobile View */
@media (max-width: 480px) {
  .appointment {
    padding: 2rem 1rem;
  }

  .appointment-form {
    width: 100%;
    padding: 1.5rem;
  }

  .appointment-form h3 {
    font-size: 1.25rem;
  }

  .appointment-form .form-group {
    flex-direction: column;
  }

  .appointment-form .form-group input,
  .appointment-form .form-group select,
  .appointment-form .form-group textarea {
    padding: 0.75rem;
  }

  .appointment-form .form-group.full-width {
    margin-bottom: 1rem;
  }

  .appointment-submit-button {
    font-size: 1rem;
    padding: 0.75rem;
  }

  .time-slot{
    width: 100%;
    height: 100%;
  }

  .time-slots {
    grid-template-columns: repeat(3, 1fr);
  }
}

.date-selector {
  margin: 10px 0px;
  width: 200px;
  height: 40px;
  border-radius: 5px;
  background: #81a5d175;
}

.appointment-submit-button {
  position: relative;
  display: inline-flex;
  align-items: center;
  color: #fff;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
}

.appointment-submit-button:disabled {
  background-color: #b0b0b0;
  cursor: not-allowed;
}

.loading-spinner {
  border: 3px solid rgba(0, 0, 0, 0.1);
  border-left: 3px solid #fff;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
  margin-right: 8px; /* Space between spinner and text */
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
