.info-cards {
    padding: 6rem 0;
    background: linear-gradient(135deg, #f6f9fc 0%, #ffffff 100%);
    text-align: center;
    position: relative;
    overflow: hidden;
}

.section-header {
    margin-bottom: 3rem;
    padding: 0 1rem;
}

.section-header h2 {
    font-family: 'Playfair Display', serif;
    font-size: 2.5rem;
    color: #1a1a1a;
    margin-bottom: 1rem;
    font-weight: 700;
}

.section-subtitle {
    font-size: 1.1rem;
    color: #666;
    max-width: 600px;
    margin: 0 auto;
}

.info-cards-container {
    display: flex;
    justify-content: center;
    gap: 2rem;
    padding: 0 2rem;
    max-width: 1400px;
    margin: 0 auto;
}

.card {
    flex: 1;
    background: white;
    border-radius: 20px;
    padding: 2.5rem;
    position: relative;
    transition: all 0.3s ease;
    max-width: 600px;
    backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 255, 255, 0.2);
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1);
}

.card:hover {
    transform: translateY(-5px);
    box-shadow: 0 25px 50px rgba(0, 0, 0, 0.15);
}

.card-icon {
    font-size: 2rem;
    color: #4a90e2;
    margin-bottom: 1.5rem;
}

.card h3 {
    color: #1a1a1a;
    margin-bottom: 1.5rem;
    font-size: 1.75rem;
    font-weight: 700;
    font-family: 'Playfair Display', serif;
}

.card-description {
    color: #666;
    margin-bottom: 2rem;
    line-height: 1.6;
    font-size: 1.1rem;
}

/* Timetable Styles */
.timetable-wrapper {
    background: #f8f9fa;
    border-radius: 15px;
    padding: 1rem;
    margin-top: 2rem;
}

.timetable {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    background: white;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);
}

.timetable th, .timetable td {
    padding: 1rem;
    border: none;
    border-bottom: 1px solid #eee;
}

.timetable th {
    background: linear-gradient(135deg, #4a90e2 0%, #357abd 100%);
    color: white;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 0.9rem;
    letter-spacing: 1px;
}

.timetable tr:last-child td {
    border-bottom: none;
}

.timetable td {
    background: white;
    color: #666;
    font-size: 1rem;
}

.timetable tr:hover td {
    background: #f8f9fa;
}

/* Location Card Styles */
.location-card {
    display: flex;
    flex-direction: column;
}

.location-content {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.address-block {
    background: #f8f9fa;
    padding: 1.5rem;
    border-radius: 12px;
    margin: 1rem 0;
}

.address-block h4 {
    color: #1a1a1a;
    margin-bottom: 0.5rem;
    font-size: 1.2rem;
    font-weight: 600;
}

.button-container {
    margin-top: auto;
    padding-top: 1rem;
}

.fancy-link {
    display: inline-flex;
    align-items: center;
    gap: 0.5rem;
    padding: 1rem 2rem;
    color: white;
    background: linear-gradient(135deg, #4a90e2 0%, #357abd 100%);
    border-radius: 50px;
    text-decoration: none;
    font-weight: 600;
    transition: all 0.3s ease;
    box-shadow: 0 4px 15px rgba(74, 144, 226, 0.3);
}

.fancy-link:hover {
    transform: translateY(-2px);
    box-shadow: 0 6px 20px rgba(74, 144, 226, 0.4);
}

.fancy-link .arrow {
    transition: transform 0.3s ease;
}

.fancy-link:hover .arrow {
    transform: translateX(5px);
}

/* Animations */
@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.animate-fade-in {
    animation: fadeIn 0.8s ease-out forwards;
}

/* Responsive Styles */
@media screen and (max-width: 1200px) {
    .info-cards-container {
        padding: 0 1rem;
    }
    
    .card {
        padding: 2rem;
    }
}

@media screen and (max-width: 992px) {
    .info-cards-container {
        flex-direction: column;
        align-items: center;
    }

    .card {
        width: 100%;
        max-width: 600px;
        margin-bottom: 2rem;
    }

    .section-header h2 {
        font-size: 2rem;
    }
}

@media screen and (max-width: 576px) {
    .info-cards {
        padding: 4rem 0;
    }

    .section-header h2 {
        font-size: 1.75rem;
    }

    .card {
        padding: 1.5rem;
    }

    .card h3 {
        font-size: 1.5rem;
    }

    .timetable th, .timetable td {
        padding: 0.75rem;
        font-size: 0.9rem;
    }

    .fancy-link {
        width: 100%;
        justify-content: center;
    }
}
