.about {
    padding: 6rem 0;
    background: linear-gradient(135deg, #ffffff 0%, #f6f9fc 100%);
    position: relative;
    overflow: hidden;
}

.about::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    background: radial-gradient(circle at top right, rgba(255,255,255,0.8) 0%, rgba(255,255,255,0.1) 100%);
    pointer-events: none;
}

.about-container {
    max-width: 1400px;
    margin: 0 auto;
    padding: 0 2rem;
    position: relative;
    z-index: 1;
}

.section-header {
    text-align: center;
    margin-bottom: 4rem;
}

.section-header h2 {
    font-family: 'Playfair Display', serif;
    font-size: 2.5rem;
    color: #1a1a1a;
    margin-bottom: 1rem;
    font-weight: 700;
}

.section-subtitle {
    font-size: 1.1rem;
    color: #666;
    max-width: 600px;
    margin: 0 auto;
}

.about-content {
    display: flex;
    gap: 3rem;
    justify-content: center;
    flex-wrap: wrap;
}

.founder-card {
    flex: 1;
    min-width: 300px;
    max-width: 600px;
    background: white;
    border-radius: 20px;
    overflow: hidden;
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
}

.founder-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 30px 60px rgba(0, 0, 0, 0.15);
}

.founder-image-wrapper {
    position: relative;
    width: 100%;
    height: 300px;
    overflow: hidden;
}

.founder-image-wrapper img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease;
}

.founder-card:hover .founder-image-wrapper img {
    transform: scale(1.05);
}

.image-overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 50%;
    background: linear-gradient(to top, rgba(0,0,0,0.5) 0%, transparent 100%);
}

.founder-details {
    padding: 2rem;
}

.founder-details h3 {
    font-family: 'Playfair Display', serif;
    font-size: 1.75rem;
    color: #1a1a1a;
    margin-bottom: 0.5rem;
}

.designation {
    display: block;
    color: #4a90e2;
    font-size: 1.1rem;
    margin-bottom: 1.5rem;
    font-weight: 500;
}

.quote-container {
    position: relative;
    padding: 1.5rem 0;
    margin-bottom: 2rem;
}

.quote-icon {
    color: #4a90e2;
    opacity: 0.2;
    font-size: 1.5rem;
}

.quote-icon.left {
    position: absolute;
    top: 0;
    left: 0;
}

.quote-icon.right {
    position: absolute;
    bottom: 0;
    right: 0;
}

.founder-bio {
    color: #666;
    line-height: 1.8;
    font-size: 1rem;
    padding: 0 2rem;
    margin: 1rem 0;
}

.credentials, .expertise {
    margin-top: 2rem;
}

.credentials h4, .expertise h4 {
    color: #1a1a1a;
    font-size: 1.2rem;
    margin-bottom: 1rem;
    font-weight: 600;
}

.credentials ul {
    list-style: none;
    padding: 0;
}

.credentials li {
    color: #666;
    margin-bottom: 0.5rem;
    padding-left: 1.5rem;
    position: relative;
}

.credentials li::before {
    content: '•';
    color: #4a90e2;
    position: absolute;
    left: 0;
}

.expertise-tags {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
}

.expertise-tags span {
    background: rgba(74, 144, 226, 0.1);
    color: #4a90e2;
    padding: 0.5rem 1rem;
    border-radius: 50px;
    font-size: 0.9rem;
    transition: all 0.3s ease;
}

.expertise-tags span:hover {
    background: #4a90e2;
    color: white;
    transform: translateY(-2px);
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

/* Responsive Styles */
@media screen and (max-width: 1200px) {
    .about {
        padding: 4rem 0;
    }

    .founder-card {
        max-width: 500px;
    }
}

@media screen and (max-width: 992px) {
    .section-header h2 {
        font-size: 2rem;
    }

    .about-content {
        gap: 2rem;
    }

    .founder-image-wrapper {
        height: 250px;
    }
}

@media screen and (max-width: 768px) {
    .about {
        padding: 3rem 0;
    }

    .founder-card {
        width: 100%;
        margin: 0 auto;
    }

    .founder-details {
        padding: 1.5rem;
    }

    .founder-details h3 {
        font-size: 1.5rem;
    }

    .expertise-tags {
        justify-content: center;
    }
}

@media screen and (max-width: 576px) {
    .section-header h2 {
        font-size: 1.75rem;
    }

    .founder-image-wrapper {
        height: 200px;
    }

    .founder-bio {
        padding: 0 1rem;
        font-size: 0.9rem;
    }

    .expertise-tags span {
        font-size: 0.8rem;
        padding: 0.4rem 0.8rem;
    }
}
