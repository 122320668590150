.dashboard-container {
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    max-width: 1200px;
    margin: auto;
  }
  
  .dashboard-container h1 {
    font-size: 24px;
    margin-bottom: 20px;
    color: #333;
  }
  
  .search-and-date-filter {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 20px;
  }
  
  .search-input {
    flex: 1;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    outline: none;
  }
  
  .search-input:focus {
    border-color: #007bff;
    box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
  }
  
  .date-picker {
    width: 200px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
  }
  
  .schedule-button {
    padding: 10px 20px;
    border: none;
    background-color: #007bff;
    color: white;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .schedule-button:hover {
    background-color: #0056b3;
  }
  
  .table-container {
    overflow-x: auto;
    margin-top: 20px;
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
  }
  
  thead {
    background-color: #007bff;
    color: white;
  }
  
  th, td {
    padding: 12px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }
  
  th {
    font-weight: bold;
    font-size: 16px;
  }
  
  tr:nth-child(even) {
    background-color: #f2f2f2;
  }
  
  .pagination {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    font-size: 16px;
  }
  
  .pagination button {
    padding: 10px 15px;
    border: none;
    background-color: #007bff;
    color: white;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .pagination button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  
  .pagination select {
    padding: 5px;
  }
  
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 400px;
  }
  
  .modal-content h2 {
    margin-top: 0;
  }
  
  .appointment-form {
    display: flex;
    flex-direction: column;
  }
  
  .appointment-form label {
    margin-bottom: 10px;
  }
  
  .appointment-form input {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-top: 5px;
    font-size: 16px;
  }
  
  .modal-actions {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  
  .modal-actions button {
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .modal-actions button:first-child {
    background-color: #007bff;
    color: white;
  }
  
  .modal-actions button:first-child:hover {
    background-color: #0056b3;
  }
  
  .modal-actions button:last-child {
    background-color: #ccc;
    color: #333;
  }
  
  .modal-actions button:last-child:hover {
    background-color: #999;
  }
  