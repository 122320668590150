.services-section {
    padding: 4rem 2rem;
    background: linear-gradient(135deg, #f8f9fa 0%, #ffffff 100%);
    position: relative;
}

.services-container {
    max-width: 1400px;
    margin: 0 auto;
}

.section-header {
    text-align: center;
    margin-bottom: 3rem;
}

.section-header h2 {
    font-family: 'Playfair Display', serif;
    font-size: 2.5rem;
    color: #1a1a1a;
    margin: 0;
    margin-bottom: 1rem;
}

.section-header p {
    font-size: 1.1rem;
    color: #666;
    margin: 0;
}

.services-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 2rem;
    margin-bottom: 2rem;
}

.service-card {
    background: white;
    border-radius: 20px;
    padding: 2rem;
    text-align: center;
    transition: all 0.3s ease;
    cursor: pointer;
    border: 1px solid rgba(0,0,0,0.1);
    box-shadow: 0 10px 30px rgba(0,0,0,0.1);
    position: relative;
}

.service-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 20px 40px rgba(0,0,0,0.2);
}

.service-icon {
    width: 80px;
    height: 80px;
    background: var(--service-color);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto 1.5rem;
    font-size: 2rem;
    color: white;
}

.service-card h3 {
    font-family: 'Playfair Display', serif;
    font-size: 1.5rem;
    color: #1a1a1a;
    margin: 0 0 1rem;
}

.service-card p {
    color: #666;
    margin: 0 0 1.5rem;
    line-height: 1.6;
}

.view-details-btn {
    background: transparent;
    border: 2px solid var(--service-color);
    color: var(--service-color);
    padding: 0.75rem 1.5rem;
    border-radius: 50px;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s ease;
}

.view-details-btn:hover {
    background: var(--service-color);
    color: white;
}

/* Modal Styles */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0,0,0,0.7);
    backdrop-filter: blur(5px);
    z-index: 9998;
}

.service-modal {
    position: fixed;
    top: 10%;
    transform: translate(-50%, -50%);
    background: white;
    border-radius: 20px;
    padding: 2rem;
    width: 90%;
    max-width: 600px;
    max-height: 85vh;
    overflow-y: auto;
    z-index: 9999;
    box-shadow: 0 20px 40px rgba(0,0,0,0.2);
}

.close-modal {
    position: absolute;
    top: 1rem;
    right: 1rem;
    background: none;
    border: none;
    font-size: 1.5rem;
    color: #666;
    cursor: pointer;
    padding: 0.5rem;
    transition: all 0.3s ease;
}

.close-modal:hover {
    color: #1a1a1a;
    transform: rotate(90deg);
}

.modal-icon {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto 1.5rem;
    font-size: 2rem;
    color: white;
}

.service-modal h3 {
    font-family: 'Playfair Display', serif;
    font-size: 2rem;
    color: #1a1a1a;
    text-align: center;
    margin: 0 0 1rem;
}

.modal-description {
    text-align: center;
    color: #666;
    margin-bottom: 2rem;
    line-height: 1.6;
}

.modal-details {
    background: #f8f9fa;
    border-radius: 15px;
    padding: 2rem;
    margin-bottom: 2rem;
}

.detail-section {
    margin-bottom: 2rem;
}

.detail-section:last-child {
    margin-bottom: 0;
}

.detail-section h4 {
    font-family: 'Playfair Display', serif;
    color: #1a1a1a;
    margin: 0 0 1rem;
}

.detail-section ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.detail-section li {
    padding-left: 1.5rem;
    position: relative;
    margin-bottom: 0.75rem;
    color: #666;
}

.detail-section li::before {
    content: '✓';
    position: absolute;
    left: 0;
    color: var(--service-color);
}

.session-info {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
    margin-top: 2rem;
    padding-top: 1.5rem;
    border-top: 1px dashed rgba(0,0,0,0.1);
}

.info-item {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.info-item .label {
    font-weight: 600;
    color: #1a1a1a;
}

.info-item .value {
    color: #666;
}

.book-service-btn {
    width: 100%;
    padding: 1rem;
    color: white;
    border: none;
    border-radius: 10px;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s ease;
}

.book-service-btn:hover {
    filter: brightness(1.1);
    transform: translateY(-2px);
}

/* Custom Scrollbar */
.service-modal::-webkit-scrollbar {
    width: 8px;
}

.service-modal::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 4px;
}

.service-modal::-webkit-scrollbar-thumb {
    background: var(--service-color);
    border-radius: 4px;
}

.service-modal::-webkit-scrollbar-thumb:hover {
    background: var(--service-color);
    filter: brightness(0.9);
}

/* Responsive Styles */
@media screen and (max-width: 992px) {
    .services-section {
        padding: 3rem 1.5rem;
    }

    .section-header h2 {
        font-size: 2rem;
    }

    .services-grid {
        grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
        gap: 1.5rem;
    }
}

@media screen and (max-width: 576px) {
    .services-section {
        padding: 2rem 1rem;
    }

    .section-header h2 {
        font-size: 1.75rem;
    }

    .service-card {
        padding: 1.5rem;
    }

    .service-icon {
        width: 60px;
        height: 60px;
        font-size: 1.5rem;
    }

    .service-card h3 {
        font-size: 1.25rem;
    }

    .service-modal {
        padding: 1.5rem;
    }

    .modal-icon {
        width: 60px;
        height: 60px;
        font-size: 1.5rem;
    }

    .service-modal h3 {
        font-size: 1.5rem;
    }

    .session-info {
        grid-template-columns: 1fr;
    }
}
