.testimony-section {
    padding: 4rem 2rem;
    background: linear-gradient(135deg, #f8f9fa 0%, #ffffff 100%);
    position: relative;
    overflow: hidden;
}

.testimony-section::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(45deg, rgba(255,255,255,0.95) 0%, rgba(255,255,255,0.5) 100%);
    z-index: 1;
}

.testimony-container {
    max-width: 1400px;
    margin: 0 auto;
    position: relative;
    z-index: 2;
}

.section-header {
    text-align: center;
    margin-bottom: 3rem;
}

.section-header h2 {
    font-family: 'Playfair Display', serif;
    font-size: 2.5rem;
    color: #1a1a1a;
    margin: 0;
    margin-bottom: 1rem;
}

.section-header p {
    font-size: 1.1rem;
    color: #666;
    margin: 0;
}

.testimonial-slider {
    position: relative;
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    min-height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.testimonial-card {
    background: white;
    border-radius: 20px;
    padding: 3rem;
    width: 100%;
    position: absolute;
    box-shadow: 0 20px 40px rgba(0,0,0,0.1);
    border: 1px solid rgba(0,0,0,0.1);
}

.quote-icon {
    position: absolute;
    font-size: 1.5rem;
    color: rgba(74,144,226,0.2);
}

.quote-icon.left {
    top: 2rem;
    left: 2rem;
}

.quote-icon.right {
    bottom: 2rem;
    right: 2rem;
}

.testimonial-content {
    text-align: center;
}

.testimonial-image {
    width: 120px;
    height: 120px;
    margin: 0 auto;
    margin-bottom: 2rem;
    position: relative;
}

.testimonial-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
    border: 4px solid white;
    box-shadow: 0 10px 20px rgba(0,0,0,0.1);
}

.testimonial-text {
    font-size: 1.1rem;
    color: #444;
    line-height: 1.8;
    margin-bottom: 1.5rem;
    font-style: italic;
}

.testimonial-rating {
    color: #ffc107;
    font-size: 1.2rem;
    margin-bottom: 1rem;
}

.testimonial-rating svg {
    margin: 0 2px;
}

.testimonial-name {
    font-family: 'Playfair Display', serif;
    font-size: 1.5rem;
    color: #1a1a1a;
    margin: 0;
    margin-bottom: 0.5rem;
}

.testimonial-role {
    color: #666;
    font-size: 1rem;
    margin: 0;
}

.nav-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: white;
    border: none;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.2rem;
    color: #1a1a1a;
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: 0 5px 15px rgba(0,0,0,0.1);
    z-index: 10;
}

.nav-button:hover {
    background: #4a90e2;
    color: white;
    transform: translateY(-50%) scale(1.1);
}

.nav-button.prev {
    left: -25px;
}

.nav-button.next {
    right: -25px;
}

.testimonial-dots {
    position: absolute;
    bottom: -2rem;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    gap: 0.5rem;
}

.dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #ddd;
    border: none;
    padding: 0;
    cursor: pointer;
    transition: all 0.3s ease;
}

.dot.active {
    background: #4a90e2;
    transform: scale(1.2);
}

/* Responsive Styles */
@media screen and (max-width: 992px) {
    .testimony-section {
        padding: 3rem 1.5rem;
    }

    .section-header h2 {
        font-size: 2rem;
    }

    .testimonial-card {
        padding: 2rem;
    }

    .nav-button {
        width: 40px;
        height: 40px;
        font-size: 1rem;
    }
}

@media screen and (max-width: 576px) {
    .testimony-section {
        padding: 2rem 1rem;
    }

    .section-header h2 {
        font-size: 1.75rem;
    }

    .testimonial-card {
        padding: 1.5rem;
    }

    .testimonial-image {
        width: 100px;
        height: 100px;
    }

    .testimonial-text {
        font-size: 1rem;
    }

    .testimonial-name {
        font-size: 1.25rem;
    }

    .nav-button {
        display: none;
    }
}