.footer {
    background: linear-gradient(135deg, #004080 0%, #002b57 100%);
    color: white;
    padding: 4rem 0 2rem;
    position: relative;
    overflow: hidden;
}

.footer::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 4px;
    background: linear-gradient(135deg, #4a90e2 0%, #357abd 100%);
}

.footer .footer-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 2rem;
}

.footer-content {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 3rem;
    margin-bottom: 3rem;
}

.footer-section {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.footer-section h3 {
    font-family: 'Playfair Display', serif;
    font-size: 1.5rem;
    margin-bottom: 1.5rem;
    color: #fff;
    position: relative;
    display: inline-block;
}

.footer-section h3::after {
    content: '';
    position: absolute;
    bottom: -8px;
    left: 0;
    width: 40px;
    height: 2px;
    background: #4a90e2;
}

.footer-section ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.footer-section ul.nav-links {
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
}

.footer-section ul.nav-links li a {
    color: rgba(255, 255, 255, 0.8);
    text-decoration: none;
    transition: all 0.3s ease;
    display: inline-block;
    padding: 0.3rem 0;
}

.footer-section ul.nav-links li a:hover {
    color: #fff;
    transform: translateX(5px);
}

.footer-section p {
    color: rgba(255, 255, 255, 0.8);
    line-height: 1.6;
    margin: 0.8rem 0;
}

.footer-bottom {
    text-align: center;
    margin-top: 3rem;
    padding-top: 2rem;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    color: rgba(255, 255, 255, 0.6);
}

@media (max-width: 768px) {
    .footer {
        padding: 3rem 0 2rem;
    }

    .footer-content {
        grid-template-columns: 1fr;
        gap: 2rem;
    }

    .footer-section {
        text-align: center;
    }

    .footer-section h3::after {
        left: 50%;
        transform: translateX(-50%);
    }

    .footer-section ul.nav-links {
        align-items: center;
    }

    .footer-section ul.nav-links li a:hover {
        transform: translateX(0) scale(1.05);
    }
}

@media (max-width: 480px) {
    .footer .footer-container {
        padding: 0 1.5rem;
    }

    .footer-section h3 {
        font-size: 1.3rem;
    }

    .footer-section p, 
    .footer-section ul.nav-links li a {
        font-size: 0.95rem;
    }
}
