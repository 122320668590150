/* General Styles */
.patient-page-container {
  padding: 20px;
  background-color: #f0f2f5;
  min-height: 100vh;
}

.appointments-page-search{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.show-appointment-actions{
  display: flex;
  gap: 15px;
}

.appointments-display{
  padding: 1rem;
}

.appointments-page-header{
  padding: 1rem;
}

h2 {
  text-align: center;
  margin-bottom: 20px;
  font-size: 24px;
  font-weight: bold;
}

.patient-page-header {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.patient-page-header > * {
  margin-bottom: 10px;
}

.patient-page-header .ant-input-search,
.patient-page-header .ant-picker,
.patient-page-header .ant-btn {
  margin-right: 10px;
}

/* Table Styles */
.ant-table {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.ant-table-thead > tr > th {
  background-color: #fafafa;
  font-weight: bold;
  color: #333;
}

.ant-table-tbody > tr > td {
  font-size: 14px;
}

.ant-table-tbody > tr:hover {
  background-color: #f6f8fb;
}

.ant-table-tbody > tr > td button {
  transition: color 0.3s ease;
}

.ant-table-tbody > tr > td button:hover {
  color: #40a9ff;
}

/* Modal Styles */
.ant-modal-title {
  font-weight: bold;
  text-align: center;
}

.ant-modal-content {
  border-radius: 8px;
}

.ant-modal-header {
  border-radius: 8px 8px 0 0;
  background-color: #f0f2f5;
}

.ant-modal-footer {
  display: flex;
  justify-content: center;
}

.ant-modal-body {
  max-height: 400px;
  overflow-y: auto;
}

/* Registration Form Styles */
.form {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}

.form-group {
  flex: 1 1 100%;
  display: flex;
  flex-direction: column;
  position: relative;
}

.form-group label {
  margin-bottom: 5px;
  font-weight: bold;
  color: #333;
}

.form-group input,
.form-group select,
.form-group textarea {
  padding: 8px 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 14px;
  transition: border-color 0.3s ease;
}

.form-group input:focus,
.form-group select:focus,
.form-group textarea:focus {
  border-color: #40a9ff;
  outline: none;
}

.form-group .input-error {
  border-color: #ff4d4f;
}

.error-message {
  color: #ff4d4f;
  font-size: 12px;
  position: absolute;
  bottom: -20px;
  left: 0;
}

button[type="submit"] {
  background-color: #1890ff;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 16px;
  transition: background-color 0.3s ease;
  cursor: pointer;
  align-self: center;
}

button[type="submit"]:hover {
  background-color: #40a9ff;
}

/* Responsive Styles */
@media screen and (max-width: 768px) {
  .patient-page-header {
    flex-direction: column;
    align-items: flex-start;
  }

  .form {
    flex-direction: column;
  }

  .form-group {
    flex: 1 1 auto;
  }

  .ant-modal-body .ant-list-item {
    flex-direction: column;
    align-items: flex-start;
  }

  .ant-modal-body .ant-list-item strong {
    margin-bottom: 5px;
  }

  .patient-page-header .ant-input-search,
  .patient-page-header .ant-picker,
  .patient-page-header .ant-btn {
    margin-right: 0;
    width: 100%;
    margin-bottom: 10px;
  }
}
