.appointment-management-container {
    padding: 20px;
}

.appointment-management-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.appointment-management-actions{
    display: flex;
    gap: 15px;
}

@media (max-width: 768px) {
    .appointment-management-header {
        flex-direction: column;
        align-items: flex-start;
    }

    .appointment-management-header > button {
        margin-top: 10px;
        width: 100%;
    }
}

.ant-modal .ant-form-item {
    margin-bottom: 16px;
}

.ant-table-wrapper {
    overflow-x: auto;
}
