.appointment-form-container {
    padding: 2rem;
    position: relative;
    background: white;
}

.close-form {
    position: absolute;
    top: 1rem;
    right: 1rem;
    background: none;
    border: none;
    font-size: 1.5rem;
    color: #666;
    cursor: pointer;
    padding: 0.5rem;
    transition: all 0.3s ease;
    z-index: 1;
}

.close-form:hover {
    color: #1a1a1a;
    transform: rotate(90deg);
}

.form-header {
    text-align: center;
    margin-bottom: 2rem;
}

.form-header h2 {
    font-family: 'Playfair Display', serif;
    font-size: 2rem;
    color: #1a1a1a;
    margin: 0;
    margin-bottom: 0.5rem;
}

.form-header p {
    color: #666;
    margin: 0;
}

.appointment-form {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.form-group {
    position: relative;
}

.form-row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
}

.input-icon {
    position: absolute;
    left: 1rem;
    top: 50%;
    transform: translateY(-50%);
    color: #4a90e2;
    font-size: 1.1rem;
}

.form-group input,
.form-group select,
.form-group textarea {
    width: 100%;
    padding: 1rem;
    padding-left: 3rem;
    border: 2px solid #eee;
    border-radius: 10px;
    font-size: 1rem;
    color: #1a1a1a;
    transition: all 0.3s ease;
}

.form-group textarea {
    resize: vertical;
    min-height: 120px;
    padding-top: 1rem;
}

.form-group input[type="date"],
.form-group input[type="time"] {
    padding-left: 3rem;
}

.form-group select {
    appearance: none;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%234a90e2' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: right 1rem center;
    background-size: 1.2rem;
    padding-right: 3rem;
}

.form-group input:focus,
.form-group select:focus,
.form-group textarea:focus {
    outline: none;
    border-color: #4a90e2;
    box-shadow: 0 0 0 3px rgba(74, 144, 226, 0.1);
}

.submit-btn {
    background: linear-gradient(135deg, #4a90e2 0%, #357abd 100%);
    color: white;
    padding: 1rem;
    border: none;
    border-radius: 10px;
    font-size: 1.1rem;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s ease;
    margin-top: 1rem;
}

.submit-btn:hover {
    transform: translateY(-2px);
    box-shadow: 0 5px 15px rgba(0,0,0,0.2);
}

/* Custom date and time input styles */
input[type="date"]::-webkit-calendar-picker-indicator,
input[type="time"]::-webkit-calendar-picker-indicator {
    opacity: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    cursor: pointer;
}

/* Responsive Styles */
@media screen and (max-width: 768px) {
    .appointment-form-container {
        padding: 1.5rem;
    }

    .form-header h2 {
        font-size: 1.75rem;
    }

    .form-row {
        grid-template-columns: 1fr;
    }
}

@media screen and (max-width: 576px) {
    .appointment-form-container {
        padding: 1rem;
        height: 100%;
        overflow-y: auto;
    }

    .form-header h2 {
        font-size: 1.5rem;
    }

    .form-group input,
    .form-group select,
    .form-group textarea {
        font-size: 16px; /* Prevents zoom on mobile */
    }

    .submit-btn {
        position: sticky;
        bottom: 1rem;
        margin-top: 2rem;
    }
}
